import { decryptData } from '@/components4x/utils/utils4x';

async function loadPermissions(store) {
  const id = localStorage.getItem('pop2utts921');
  if (id === null) return;

  let decrypt = decryptData(id).split(',');
  await store.dispatch('updatePermission', decrypt);
}

export { loadPermissions };
